var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex items-center flex-col w-full justify-center font-inter"},[(!_vm.hideExpandedView)?_c('div',{staticClass:"rounded-lg list-group w-full"},[_c('div',{staticClass:"mb-5 mx-10"},[_c('div',{staticClass:"flex flex-row justify-start items-center px-4 rounded-lg hover:border border-tasqBase100",staticStyle:{"background":"#0d2136"}},[_c('span',{staticClass:"inline-block",class:_vm.listItem.checked ? 'tasq-checkbox--checked' : 'tasq-checkbox checkbox-style',attrs:{"type":"checkbox"},on:{"click":function($event){_vm.listItem.checked = !_vm.listItem.checked}}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.listItem.text),expression:"listItem.text"}],staticClass:"border-none resize-none text-white inline-block rounded-sm bg-transparent",staticStyle:{"width":"calc(100% - 40px)"},attrs:{"rows":"1","placeholder":"Type here to add  to To Do List"},domProps:{"value":(_vm.listItem.text)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.addItemToList.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.listItem, "text", $event.target.value)}}})]),_c('div',{staticClass:"flex my-5 ml-0.5 group-radio-btn flex-row justify-start gap-x-5"},[_c('t-radio-group',{staticClass:"text-white",attrs:{"options":['None', 'Date']},model:{value:(_vm.listItem.dateType),callback:function ($$v) {_vm.$set(_vm.listItem, "dateType", $$v)},expression:"listItem.dateType"}}),_c('div',{},[_c('tasq-button',{staticClass:"font-inter h-8 w-16 float-right",class:_vm.listItem.dateType === 'Date' ? 'mt-9' : '',attrs:{"type":"primary","disabled":!_vm.listItem.text ||
              (_vm.listItem.dateType === 'Date' && !_vm.listItem.dueDate),"size":"small"},on:{"click":_vm.addItemToList}},[_vm._v(" Save ")])],1)],1),(_vm.listItem.dateType === 'Date')?_c('t-datepicker',{staticClass:"customized-datepicker customized-datepicker--align-right rounded-sm mr-3 text-white bg-transparent w-full",staticStyle:{"background":"transparent","display":"inline-block"},attrs:{"user-format":'m/d/y',"datepicker":"","placeholder":"To Date"},model:{value:(_vm.listItem.dueDate),callback:function ($$v) {_vm.$set(_vm.listItem, "dueDate", $$v)},expression:"listItem.dueDate"}}):_vm._e()],1),_c('draggable',{staticClass:"mx-3",attrs:{"id":`steps-list-input`,"disabled":_vm.hideAddNewItem,"list":_vm.pendingItems || [],"ghost-class":"tasq-item-ghost"}},_vm._l((_vm.pendingItems),function(item,key){return _c('div',{key:key,staticClass:"flex mb-3 justify-between items-center",on:{"mouseover":function($event){return _vm.updateHoverItem(item)},"mouseleave":function($event){_vm.hoverItemId = ''}}},[_c('mdicon',{staticClass:"cursor-pointer flex-none text-tasqNeutral300",attrs:{"name":"drag-horizontal-variant","size":"20"}}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-end",value:({
            show: false,
            theme: 'tasq-tooltip',
            content: `Date: ${item.dueDate || 'None'}`,
          }),expression:"{\n            show: false,\n            theme: 'tasq-tooltip',\n            content: `Date: ${item.dueDate || 'None'}`,\n          }",modifiers:{"bottom-end":true}}],staticClass:"flex w-full mx-2 flex-row justify-start items-start px-4 rounded-lg hover:border border-primary500 shadow item-bg"},[_c('span',{staticClass:"inline-block mt-3",class:item.checked ? 'tasq-checkbox--checked' : 'tasq-checkbox checkbox-style',attrs:{"type":"checkbox"},on:{"click":function($event){item.checked = !item.checked;
              _vm.updateList();}}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(item.text),expression:"item.text"}],ref:item.id,refInFor:true,staticClass:"border-none resize-none w-full outline-none text-white inline-block rounded-lg bg-transparent",attrs:{"rows":"1","disabled":_vm.hideAddNewItem},domProps:{"value":(item.text)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(item, "text", $event.target.value)},_vm.updateListText]}})]),(!_vm.hideAddNewItem)?_c('span',{staticClass:"material-icons cursor-pointer flex-none",on:{"click":function($event){return _vm.removeItemFromList(item)}}},[_c('mdicon',{staticClass:"cursor-pointer text-tasqNeutral300",class:item.id === _vm.hoverItemId
                ? 'text-tasqAccentError'
                : ' text-tasqNeutral300',attrs:{"name":"trash-can","size":"20"}})],1):_vm._e()],1)}),0),(_vm.completeItems.length)?_c('div',[_c('p',{staticClass:"text-lg uppercase text-white font-bold px-4 py-4"},[_vm._v(" Completed ")]),_c('draggable',{staticClass:"mx-3",attrs:{"id":`steps-list-input`,"disabled":_vm.hideAddNewItem,"list":_vm.completeItems || [],"ghost-class":"tasq-item-ghost "}},_vm._l((_vm.completeItems),function(item,key){return _c('div',{key:key,staticClass:"flex mb-3 justify-between items-center",on:{"mouseover":function($event){return _vm.updateHoverItem(item)},"mouseleave":function($event){_vm.hoverItemId = ''}}},[_c('mdicon',{staticClass:"cursor-pointer flex-none text-tasqNeutral300",attrs:{"name":"drag-horizontal-variant","size":"20"}}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-end",value:({
            show: false,
            theme: 'tasq-tooltip',
            content: `Date: ${item.dueDate || 'None'}`,
          }),expression:"{\n            show: false,\n            theme: 'tasq-tooltip',\n            content: `Date: ${item.dueDate || 'None'}`,\n          }",modifiers:{"bottom-end":true}}],staticClass:"flex w-full mx-2 flex-row justify-start items-start px-4 rounded-lg shadow border-accentSuccess200 bg-accentSuccess200 bg-opacity-10 border"},[_c('span',{staticClass:"inline-block mt-3 border-accentSuccess200 bg-accentSuccess200",class:item.checked
                  ? 'tasq-checkbox--checked--complete'
                  : 'tasq-checkbox checkbox-style',staticStyle:{"background":"theme('colors.accentSuccess200') !important"},attrs:{"type":"checkbox"},on:{"click":function($event){item.checked = !item.checked;
                _vm.updateList();}}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(item.text),expression:"item.text"}],ref:item.id,refInFor:true,staticClass:"border-none resize-none w-full outline-none text-accentSuccess200 text-base inline-block rounded-lg bg-transparent",attrs:{"rows":"1","disabled":_vm.hideAddNewItem},domProps:{"value":(item.text)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(item, "text", $event.target.value)},_vm.updateListText]}})]),(!_vm.hideAddNewItem)?_c('span',{staticClass:"material-icons cursor-pointer flex-none",on:{"click":function($event){return _vm.removeItemFromList(item)}}},[_c('mdicon',{staticClass:"cursor-pointer text-tasqNeutral300",class:item.id === _vm.hoverItemId
                  ? 'text-accentError200'
                  : ' text-tasqNeutral300',attrs:{"name":"trash-can","size":"20"}})],1):_vm._e()],1)}),0)],1):_vm._e()],1):_c('div',{staticClass:"rounded-lg w-full"},[(_vm.currentItem)?_c('div',{staticClass:"flex flex-row justify-start items-center px-4 my-2 rounded-lg hover:border border-primary500 shadow item-bg",class:_vm.currentItem.checked
          ? 'border-accentSuccess200  bg-accentSuccess200 bg-opacity-10 border'
          : 'item-bg'},[_c('span',{staticClass:"inline-block",class:_vm.currentItem.checked ? 'tasq-checkbox--checked' : 'tasq-checkbox checkbox-style',attrs:{"type":"checkbox"},on:{"click":function($event){_vm.currentItem.checked = !_vm.currentItem.checked;
          _vm.updateCurrentItem();}}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentItem.text),expression:"currentItem.text"}],staticClass:"border-none resize-none text-tasqNeutral50 inline-block rounded-sm bg-transparent w-full",attrs:{"rows":"1","disabled":_vm.hideAddNewItem},domProps:{"value":(_vm.currentItem.text)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.currentItem, "text", $event.target.value)},_vm.updateCurrentItem]}})]):_vm._e(),_c('div',{staticClass:"flex flex-row justify-start items-center px-4 rounded-lg",staticStyle:{"background":"#0d2136"}},[_c('span',{staticClass:"inline-block",class:_vm.listItem.checked ? 'tasq-checkbox--checked' : 'tasq-checkbox',attrs:{"type":"checkbox"},on:{"click":function($event){_vm.listItem.checked = !_vm.listItem.checked}}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.listItem.text),expression:"listItem.text"}],staticClass:"border-none resize-none text-tasqNeutral50 inline-block rounded-sm bg-transparent w-full",attrs:{"rows":"1","placeholder":"Type here to add  to To Do List"},domProps:{"value":(_vm.listItem.text)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.addItemToList.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.listItem, "text", $event.target.value)}}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }