
import { Component, Prop, Watch } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { mixins } from 'vue-class-component';
import uniqid from 'uniqid';
import draggable from 'vuedraggable';
import { Debounce } from 'vue-debounce-decorator';
import workflowModule from '../../store/modules/workflowModule';

@Component({
  components: {
    draggable,
  },
})
export default class ChecklistComponent extends mixins() {
  @Prop({ type: Array, default: () => [] }) checklistData!: any;

  @Prop({ type: Object, default: () => {} }) tasq!: any;

  @Prop({ type: Boolean, default: false }) hideAddNewItem!: boolean;

  @Prop({ type: Boolean, default: false }) showCheckbox!: boolean;

  @Prop({ type: Boolean, default: false }) hideAddRemoveItem!: boolean;

  @Prop({ type: Boolean, default: false }) hideItemBackground!: boolean;

  @Prop({ type: Boolean, default: false }) hideExpandedView!: boolean;

  checklist: any = [];

  newItemText = '';

  currentItem: any = null;

  hoverItemId = '';

  defaultItem = {
    checked: false,
    text: '',
    dueDate: null,
    dateType: 'None',
  };

  listItem = {
    checked: false,
    text: '',
    dueDate: null,
    dateType: 'None',
  };

  async created() {
    const data = JSON.parse(JSON.stringify(this.checklistData));
    this.checklist = data.map((item) => ({
      ...item,
      isHovering: false,
    }));

    const list = await workflowModule.getWellTodolist(this.tasq.wellName);
    this.checklist = list;

    this.separateListItems();
  }

  get isWorkTicketView() {
    return this.tasqListLevel === 'WorkTicket';
  }

  get isPadView() {
    return this.tasqListLevel === 'Pad';
  }

  get tasqListLevel() {
    return tasqsListModule.tasqListLevel;
  }

  mounted() {
    this.autoResizeTextarea();
  }

  autoResizeTextarea() {
    this.$nextTick(() => {
      const elements = this.$refs;
      if (elements && Object.keys(elements)) {
        Object.keys(elements).forEach((key) => {
          if (elements[key] && elements[key]![0]) {
            const target = elements[key]![0];
            target.style.height = 'auto';
            target.style.height = `${target.scrollHeight}px`;
          }
        });
      }
    });
  }

  pendingItems: any = [];
  completeItems: any = [];

  addItemToList() {
    if (!this.listItem.text) {
      return;
    }
    this.checklist.push({
      ...this.listItem,
      id: uniqid(),
      isHovering: false,
    });
    console.log(this.checklist);
    this.$emit('update-checklist', this.checklist);
    this.autoResizeTextarea();
    this.updateList();
    this.$nextTick(() => {
      this.listItem = {
        checked: false,
        text: '',
        dueDate: null,
        dateType: 'None',
      };
    });
    this.$tasqAlert({
      title: 'Success',
      message: 'It has been successfully added to the To Do List',
      type: 'success',
    });
  }

  updateHoverItem(item) {
    this.hoverItemId = item.id;
  }

  removeItemFromList(item) {
    this.checklist = this.checklist.filter((step) => item.id !== step.id);
    console.log(item);
    console.log(this.checklist);
    this.$emit('update-checklist', this.checklist);
    this.updateList();
  }

  async separateListItems() {
    this.pendingItems = this.checklist.filter((i) => !i.checked);
    this.completeItems = this.checklist.filter((i) => i.checked);
    if (this.pendingItems.length) {
      this.currentItem = this.pendingItems[this.pendingItems.length - 1];
    } else if (this.completeItems.length) {
      this.currentItem = this.completeItems[0];
    } else {
      this.currentItem = null;
    }
  }

  async updateList() {
    this.separateListItems();
    await workflowModule.postTodolist({
      payload: [...this.pendingItems, ...this.completeItems],
      nodeID: this.tasq.wellName,
      level: this.tasq.level,
    });
  }

  async updateCurrentItem() {
    const checklist = this.checklist.map((item) => {
      if (item.id === this.currentItem.id) {
        item = this.currentItem;
      }
      return item;
    });
    this.checklist = checklist;
    await this.updateList();
  }

  @Debounce(2000)
  updateListText(e) {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
    this.$emit('update-checklist', this.checklist);
  }
}
